import { ById, PartsResponse } from "../../../react/shared/publicInterfaces";
import { IGET_USED_PARTS_VERSION_HISTORY_REQUEST, IGetUsedPartsVersionHistory, IGetUsedPartsVersionHistoryRequest } from "./getUsedPartsVersionHistoryConstants";

export interface IGetUsedPartsVersionHistoryLoadAction {
    type: IGET_USED_PARTS_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetUsedPartsVersionHistoryRequest
}
export const getUsedPartsVersionHistoryLoadAction = (data: IGetUsedPartsVersionHistoryRequest): IGetUsedPartsVersionHistoryLoadAction => ({
    type: IGET_USED_PARTS_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetUsedPartsVersionHistorySuccessAction {
    type: IGET_USED_PARTS_VERSION_HISTORY_REQUEST.SUCCESS;
    list: PartsResponse<ById<IGetUsedPartsVersionHistory>>;
}
export const getUsedPartsVersionHistoryLoadSuccessAction = (list: PartsResponse<ById<IGetUsedPartsVersionHistory>>): IGetUsedPartsVersionHistorySuccessAction => ({
    type: IGET_USED_PARTS_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetUsedPartsVersionHistoryLoadFailedAction {
    type: IGET_USED_PARTS_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getUsedPartsVersionHistoryLoadFailedAction = (message: string): IGetUsedPartsVersionHistoryLoadFailedAction => ({
    type: IGET_USED_PARTS_VERSION_HISTORY_REQUEST.FAILED,
    message
});
