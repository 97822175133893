import { ById, PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IGET_USED_PARTS_VERSION_HISTORY_REQUEST, IGetUsedPartsVersionHistory } from "./getUsedPartsVersionHistoryConstants";
import { IGetUsedPartsVersionHistoryLoadAction, IGetUsedPartsVersionHistoryLoadFailedAction, IGetUsedPartsVersionHistorySuccessAction } from "./getUsedPartsVersionHistoryActions";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";

type Actions =
    | IGetUsedPartsVersionHistoryLoadAction
    | IGetUsedPartsVersionHistorySuccessAction
    | IGetUsedPartsVersionHistoryLoadFailedAction
    | IFlushDataSuccessAction;

export const GetUsedPartsVersionHistoryReducer = (state: Server<PartsResponse<ById<IGetUsedPartsVersionHistory>>> = notLoaded, action: Actions): Server<PartsResponse<ById<IGetUsedPartsVersionHistory>>> => {
    switch (action.type) {
        case IGET_USED_PARTS_VERSION_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_USED_PARTS_VERSION_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_USED_PARTS_VERSION_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};