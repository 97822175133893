/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import styled from "styled-components";
import { HasClass } from "./publicInterfaces";
import Lightbox from 'react-awesome-lightbox';
import "react-awesome-lightbox/build/style.css";

interface ILAThumbnail extends HasClass {
    alt: string;
    url: string;
    id?: string;
};

const LAThumbnailStyles = styled.div`
    .thumbnail-img {
        border-width: 0;
        height: auto;
        max-height: 130px;
        max-width: 130px;
        vertical-align: middle;
        width: auto;
    };
`;

export const LAThumbnail: React.FC<ILAThumbnail> = React.memo((props: ILAThumbnail) =>
    <LAThumbnailStyles>
        <img id={props.id} src={props.url} className="thumbnail-img" alt={props.alt} title={props.alt} />
    </LAThumbnailStyles>
);

export const LAThumbnailWithLink: React.FC<ILAThumbnail> = React.memo((props: ILAThumbnail) => {
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    const handleClick = (): void => {
        // const imageUrls: string[] = []
        // imageUrls.push(props.url)
        setImageUrl(props.url);
    }

    const handleClose = () => {
        setImageUrl(undefined)
    }
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <>
        <a id={props.id} className="cursor" onClick={handleClick} rel="noreferrer">
            <LAThumbnail {...props} />
        </a>
        {imageUrl && <Lightbox
            image={imageUrl}
            onClose={handleClose}
        />}
    </>
    ;
}
);