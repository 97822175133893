
export enum IGET_USED_PARTS_VERSION_HISTORY_REQUEST {
    REQUEST = "getUsedPartsVersionHistory/GET_USED_PARTS_VERSION_HISTORY_REQUEST",
    SUCCESS = "getUsedPartsVersionHistory/GET_USED_PARTS_VERSION_HISTORY_SUCCESS",
    FAILED = "getUsedPartsVersionHistory/GET_USED_PARTS_VERSION_HISTORY_FAILED"
};

export interface IGetUsedPartsVersionHistoryRequest { 
    request: {
        ID: number;
        Type: string;
    },
    token: string;
};

export interface IGetUsedPartsVersionHistory {
    id: number;
    itemID: number;
    status: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    operation: number;
    make: string;
    model: string;
    serial_No: string;
    unit_No: string;
    hours: string;
    location: string;
    notes: string;
    photos_1: string;
    photos_2: string;
    photos_3: string;
    name?: string;
    part_No?: string;
    condition?: string;
    type?: string;
    description?: string;
    year?: string;
    weight?: string;
    available_Quantity?: number;
    price?: number;
    alternate_Part_No?: string;
    core_Charge?: string;
    discontinued?: string;
    core_Charge_Date?: string;
    price_Date?: string;
};