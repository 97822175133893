import { PartsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IAddPartSuccessAction } from "../add/part/addPartActions";
import { IADD_PART_REQUEST } from "../add/part/addPartConstants";
import { IGetPartsLoadAction, IGetPartsLoadFailedAction, IGetPartsSuccessAction } from "./getPartActions";
import { IGET_PARTS_REQUEST, IPart } from "././getPartConstants";
import { IFlushDataSuccessAction } from "../../toolRentals/clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../toolRentals/clearRedux/clearReduxConstants";
import { IDELETE_PART_REQUEST } from "../delete/part/deletePartConstants";
import { IDeletePartSuccessAction } from "../delete/part/deletePartActions";
import { IUpdatePartSuccessAction } from "../update/part/updatePartActions";
import { IUPDATE_PART_REQUEST } from "../update/part/updatePartConstants";

type Actions =
    | IGetPartsLoadAction
    | IGetPartsSuccessAction
    | IDeletePartSuccessAction
    | IGetPartsLoadFailedAction
    | IAddPartSuccessAction
    | IUpdatePartSuccessAction
    | IFlushDataSuccessAction;

export const GetPartsReducer = (state: Server<PartsResponse<IPart[]>> = notLoaded, action: Actions): Server<PartsResponse<IPart[]>> => {
    switch (action.type) {
        case IGET_PARTS_REQUEST.REQUEST:
            return loading;

        case IGET_PARTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PARTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_PART_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_PART_REQUEST.SUCCESS:
            return notLoaded;

        case IDELETE_PART_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};