import React, { useState } from "react";
import { STATUS_ENUM } from "../../../../redux/server";
import { IGetUsedPartsVersionHistory } from "../../../../redux/parts/getUsedPartsVersionHistory/getUsedPartsVersionHistoryConstants";
import { ById } from "../../../shared/publicInterfaces";
import { LAPopover } from "../../../shared/popOver";
import { LAPaperWithPadding } from "../../../shared/paper";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LACenteredLoading } from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { undefinedFunction } from "../../../shared/constExports";
import Lightbox from 'react-awesome-lightbox';
import "react-awesome-lightbox/build/style.css";

interface IDismantledEquipmentVersionHistoryPopupProps {
    open: boolean;
    unitNo: string | undefined;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: ById<IGetUsedPartsVersionHistory>;
};

export const DismantledEquipmentVersionHistoryPopup: React.FC<IDismantledEquipmentVersionHistoryPopupProps> = React.memo((props: IDismantledEquipmentVersionHistoryPopupProps) => {
    const data = Object.values(props.data);
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    const handleImageClick = (name: string, url: string): void => {
        // console.log(url)
        setImageUrl(url);
        // if (url.includes("https")) {
        //     window.open(url, "_blank");
        // } 
        // else {
        //     let img = new Image();
        //     img.src = url;
        //     let win: any = window.open();
        //     win.document.write("<img src='" + img.src + "'/>");
        //     win.onload = function () {
        //         win.document.close();
        //     };
        // }
    }
    const handleClose = () => {
        setImageUrl(undefined)
    }

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null}>
        <LAPaperWithPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>VERSION HISTORY {props.unitNo && `- ${props.unitNo}`}</h3>
                    <hr />
                </LAGridItem>

                {props.status === STATUS_ENUM.LOADING && <LAGridItem xs={12}>
                    <LACenteredLoading message="Loading Version History..." />
                </LAGridItem>}

                {props.status === STATUS_ENUM.FAILED && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.SUCCEEDED) && (data && data.length === 0)
                    && <LAGridItem xs={12}>
                        <LAErrorBox text="No records Found" />
                    </LAGridItem>}

                <LAGridItem xs={12}>
                    <LADevExtremeGrid
                        data={data}
                        height={600}
                        id="dismantledEquipmentVersionHistory"
                        columnChoose={true}
                        columnsHiding={true}
                        onClick={undefinedFunction}
                        searchPanel={true}
                        filterHeader={true}
                        export={true}
                        onImageClick={handleImageClick}
                        removeStyleBtn={true}
                        columns={[
                            { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                            { name: "modified_By", caption: "Modified By", type: "string" },
                            { name: "serial_No", caption: "Serial No.", type: "string" },
                            { name: "make", caption: "Make", type: "string" },
                            { name: "model", caption: "Model", type: "string" },
                            // { name: "unit_No", caption: "Unit No", type: "string" },
                            { name: "status", caption: "Status", type: "string" },
                            { name: "hours", caption: "Hours", type: "string" },
                            { name: "location", caption: "Location", type: "string" },
                            { name: "notes", caption: "Notes", type: "string" },
                            { name: "photos_1", caption: "Photos 1", type: "picture", width: 80 },
                            { name: "photos_2", caption: "Photos 2", type: "picture", width: 80 },
                            { name: "photos_3", caption: "Photos 3", type: "picture", width: 80 },
                            { name: "created", caption: "Created", type: "datetime", show: false },
                            { name: "created_By", caption: "Created By", type: "string",  show: false},
                            
                        ]}
                    />
                </LAGridItem>

            </LAGrid>
            {imageUrl && <Lightbox
                image={imageUrl}
                onClose={handleClose}
            />}
        </LAPaperWithPadding>
    </LAPopover>
});