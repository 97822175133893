import { PARTS_END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_USED_PARTS_VERSION_HISTORY_REQUEST, IGetUsedPartsVersionHistory } from "./getUsedPartsVersionHistoryConstants";
import { ById, PartsResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetUsedPartsVersionHistoryLoadAction, IGetUsedPartsVersionHistoryLoadFailedAction, IGetUsedPartsVersionHistorySuccessAction, 
    getUsedPartsVersionHistoryLoadFailedAction, getUsedPartsVersionHistoryLoadSuccessAction } from "./getUsedPartsVersionHistoryActions";

export const getUsedPartsVersionHistoryEpic: Epic = (
    action$: ActionsObservable<IGetUsedPartsVersionHistoryLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetUsedPartsVersionHistorySuccessAction | IGetUsedPartsVersionHistoryLoadFailedAction> =>
    action$.ofType(IGET_USED_PARTS_VERSION_HISTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<PartsResponse<ById<IGetUsedPartsVersionHistory>>>(
                    PARTS_END_POINTS.GET_PARTS.GET_USED_PARTS_VERSION_HISTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: PartsResponse<ById<IGetUsedPartsVersionHistory>>): IGetUsedPartsVersionHistorySuccessAction => {
                            return getUsedPartsVersionHistoryLoadSuccessAction(response);
                        }),
                        catchError((response: PartsResponse<string>) => ActionsObservable.of(getUsedPartsVersionHistoryLoadFailedAction(response.message)))
                    )
            )
        );